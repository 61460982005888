import React from 'react'
import MainLayout from '../layouts/MainLayout'
import { Link, graphql } from 'gatsby'
import { ContextProvider } from 'gatsby-theme-shopify-manager'

const shopName = process.env.GATSBY_SHOPIFY_SHOP_NAME
const accessToken = process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN

const Home = ({ data }) => {
  return (
    <ContextProvider shopName={shopName} accessToken={accessToken}>
      <MainLayout>
        <div className="container mx-auto flex items-center flex-wrap pt-8">
          {data.allShopifyProduct.edges.map(({ node }) => {
            const { shopifyId, title, images, handle, priceRange } = node
            const [image] = images

            return (
              <div
                key={shopifyId}
                className="w-full md:w-1/3 xl:w-1/4 p-6 flex flex-col"
              >
                <Link to={`product/${handle}`}>
                  <img
                    className="opacity-100 md:opacity-75 hover:opacity-100 focus:opacity-100 transition-opacity ease-linear duration-75"
                    src={image.originalSrc}
                    alt={title}
                  />
                  <div className="pt-3 flex items-center justify-between text-sm">
                    {title}
                  </div>
                  <h2 className="pt-1 font-bold text-gray-900">
                    ${parseInt(priceRange.maxVariantPrice.amount)}
                  </h2>
                </Link>
              </div>
            )
          })}
        </div>
      </MainLayout>
    </ContextProvider>
  )
}

export default Home

export const query = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          title
          shopifyId
          description
          images {
            originalSrc
          }
          handle
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`
